*{

  scroll-behavior: smooth;
}
.slide .slick-slide img{
  max-width: 100%;
}

.slide .slick-prev{
  margin: 0 0 0 .7em;
  left: 60px;
  display: inline-block;
  position: absolute;
  top: 40%;
  color: #ffffff !important;
  outline: none !important;
  background: transparent !important;
  z-index: 100;
}
.slide .slick-prev::before{
  content: url('https://q.megatravel.com.mx/img/elements/mega-ofertas/carrusel/arrow-izq.png') !important;
}
.slide .slick-next{
  margin: 0 .7em 0 0;
  right: 60px;
  display: inline-block;
  position: absolute;
  top: 40%;
  
  color: #ffffff !important;
  outline: none !important;
  background: transparent !important;
}
.slide .slick-next::before{
  content: url('https://q.megatravel.com.mx/img/elements/mega-ofertas/carrusel/arrow-der.png') !important;
}
.slick_item {
  padding: 5px 15px;
}
.map{
  border: 0;
}
.main-text:hover{
  color: #000 !important; 
}

#exampleModal2 .slick-slider .slick-slide{
  padding: 5px;
}

#exampleModal3 .slick-slider .slick-slide{
  padding: 5px;
}

#exampleModal4 .slick-slider .slick-slide{
  padding: 5px;
}

#exampleModal5 .slick-slider .slick-slide{
  padding: 5px;
}

#exampleModal6 .slick-slider .slick-slide{
  padding: 5px;
}

.img-responsive{
  width: 100%;
}

.iframe-detail{
  border: 0;
}

@media only screen and (max-width: 650px) {
  .slide_movil {
    display: block!important;
    width: 100%;
    height: auto;
}
.slide_desktop{
  display: none!important;
}

}